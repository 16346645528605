<template>
    <div v-if="transactions.reservation_list">
        <table v-if="transactions.reservation_list.length > 0" class="table table-hover">
            <thead>
            <tr>
                <th colspan="2">Prenotazione</th>
                <th>Data</th>
                <th>Offerta</th>
                <th>Utente</th>
                <th>Tipo</th>
                <th>Pagamento</th>
                <th class="text-right">Lordo</th>
                <th class="text-right">Fee</th>
                <th class="text-right">Netto</th>
            </tr>
            </thead>
            <tbody id="reservation-list">
            <TransactionRow v-for="transaction in transactions.reservation_list" :transaction='transaction' v-bind:key ='transaction.id'/>
            </tbody>
            <tfoot>
            <tr>
                <td colspan="7"></td>
                <td class="text-right"><b>€<span id="price_final_total">{{transactions.price_final_total}}</span></b></td>
                <td class="text-right">€<span id="fee_total">{{transactions.fee_total}}</span></td>
                <td class="text-right">€<span id="price_net_total">{{transactions.price_net_total}}</span></td>
            </tr>
            </tfoot>
        </table>
        <span v-else class="text-muted">
            Non sono presenti transazioni da mostrare
        </span>
    </div>

</template>

<script>
    import TransactionRow from '@/components/Transaction/TransactionRow'
    export default {
        name: "TransactionTable",
        components: {TransactionRow},
        props: {
            transactions: {
                type: Object,
                required: true
            }
        }
    }
</script>
